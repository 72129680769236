<template>
    <div class="goods-info-content">
        <el-table :data="in_stockGoodsList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="1%">
            <el-table-column label="商品信息">
                <template slot-scope="scope">
                    <div class="goods-info">
                        <div class="info">
                            <span style="margin-right: 25px;">商品编码：{{scope.row.good_sku}}</span>
                            <span style="margin-right: 25px;">所属类目：{{scope.row.name}}</span>
                            <span>商品货号：{{scope.row.good_no}}</span>
                        </div>
                        <div class="img">
                            <img :src="scope.row.good_images | goodsImg" alt="">
                            <span class="goods-name">{{scope.row.good_title}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="stock" label="总库存" align="center" width="300px"></el-table-column>
            <el-table-column prop="sales_price" label="销售价" align="center" width="300px"></el-table-column>
            <el-table-column label="操作" align="center" width="150px">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false">
                        <router-link class="a-link" :to="{path: '/goods/detail', query: {g_id: scope.row.id, type: 1 }}" target="_blank">查看详情</router-link>
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="in_stockGoodsPages.currentPageNum"
                       :page-size="in_stockGoodsPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="in_stockGoodsPages.total"
                       @current-change="in_stockGoodsPagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { StuOpSocialGetSalesGoodsData } from '@/utils/apis.js'
    export default {
        name: "GoodsManage",
        data() {
            return {
                //商品列表
                in_stockGoodsList: [],
                op_id: Number(this.$route.query.op_id) || null,
                //分页
                in_stockGoodsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getIn_stocktGoodsList();
        },
        filters: {
           goodsImg(value) {
              return value[0].length > 1 ? value[0] : value;
           }
        },
        methods: {
            //获取热门商品列表
            getIn_stocktGoodsList() {
                let param = {
                    page: this.in_stockGoodsPages.currentPageNum,
                    limit: this.in_stockGoodsPages.eachPageNum,
                    op_id: this.op_id
                }
                StuOpSocialGetSalesGoodsData(param).then((res) => {
                    this.in_stockGoodsList = res.data.list;
                    this.in_stockGoodsPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //分页
            in_stockGoodsPagesCurrentChange(val) {
                this.in_stockGoodsPages.currentPageNum = val;
                this.getIn_stocktGoodsList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .goods-info-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .customTable {
            .goods-info {
                display: flex;
                flex-direction: column;
                .info {
                    margin-bottom: 10px;
                }
                .img {
                    display: flex;
                    img {
                        width: 80px;
                        height: 80px;
                        margin-right: 10px;
                    }
                }

                .goods-name {
                    flex: 1;
                    width: 1%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    font-size: 16px;
                }
            }
            .a-link {
                font-size: 16px;
                color: #18B063;
            }
            ::v-deep {
                .el-table__empty-block {
                    background-image: url("../../../../../assets/images/student/goods-null-data.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    .el-table__empty-text {
                        margin-top: 150px;
                    }
                }
            }
        }
    }
</style>